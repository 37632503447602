"use client";

import { BannerRow } from "daskapital-ds";
import { BlocksProps } from "@/lib/wordpress/_types/blocksStyle";
import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import Link from "next/link";
import displayBlock from "@/lib/wordpress/blocks/displayBlock";
import getBlockStyles from "@/lib/wordpress/blocks/getBlockStyles";
import { useMemo } from "react";

export type IBlockBannerRow = {
  attrs: {
    anchor?: string;
    label?: string;
    mediaUrl?: string;
    mediaSize?: string;
    href?: {
      url?: string,
      newTab?: string,
    };
    className?: string;
    style?: Record<string, unknown>;
  };
  innerBlocks: BlocksProps[];
};

export default function BlockBannerRow({
  attrs,
  innerBlocks,
}: IBlockBannerRow) {
  const { mediaUrl, mediaSize, href, className, style } =
    attrs || {};

  const elStyle = useMemo(() => ({
    ...getBlockStyles({
      style,
    })
  }), [style]);

  let mediaSizes = mediaSize ? mediaSize.split("x") : [];
  // No button href? Bail.
  const url = href?.url;
  const newTab = href?.newTab;

  const linkTarget = newTab ? "_blank" : "_parent";

  return (
    <BannerRow
      imageElement={
        mediaUrl ? (
          <DisplayImage
            url={mediaUrl as string}
            width={parseInt(mediaSizes[0])}
            height={parseInt(mediaSizes[1])}
          />
        ) : undefined
      }
      linkElement={
        <Link
          href={url as string}
          target={linkTarget}
          role="link"
          className="linker"
        ></Link>
      }
      className={className}
      style={elStyle}
    >
      {!!innerBlocks?.length &&
        innerBlocks?.map((block: BlocksProps, index: number) => {
          return displayBlock(block, index);
        })}
    </BannerRow>
  );
}
