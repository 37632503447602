"use client";

export type IBlockHtml = {
  attrs: {
    content?: string;
    className?: string;
    style?: Record<string, unknown>;
  };
};

export default function BlockHtml({ attrs }: IBlockHtml) {
  const {
    content,
    className,
    style,
  } = attrs || {};

  if (!content) return <></>

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: content }}
    >
    </div>
  );
}
