"use client";

import React, { useEffect, useState } from "react";
import { Tabs, TabsItem } from "daskapital-ds";

import { BlocksProps } from "@/lib/wordpress/_types/blocksStyle";
import displayBlock from "@/lib/wordpress/blocks/displayBlock";

export type IBlockTabs = {
  attrs: {
    title?: string;
    vertical?: boolean;
    mode?: string;
    className?: string;
    style?: Record<string, unknown>;
  };
  innerBlocks: BlocksProps[];
};
export default function BlockTabs({ attrs, innerBlocks }: IBlockTabs) {
  const { mode, className } = attrs || {};

  const [hash, setHash] = useState("");

  useEffect(() => {
    setHash(window.location.hash.slice(1));
  }, []);

  const activeElement = innerBlocks.findIndex(x => x?.attrs?.anchor === hash);

  return (
    <Tabs mode={mode} $activeEl={activeElement === -1 ? 0 : activeElement} className={className}>
      {!!innerBlocks?.length &&
        innerBlocks.map((item: BlocksProps, index: number) => {
          const attrs = item?.attrs as Record<string, unknown>;
          return (
            <TabsItem key={`tabs-item${index}`} label={attrs.title as string}>
              {!!item?.innerBlocks?.length &&
                item?.innerBlocks?.map((block: BlocksProps, index: number) => {
                  return displayBlock(block, index);
                })}
            </TabsItem>
          );
        })}
    </Tabs>
  );
}
