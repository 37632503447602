"use client";

import { Icon, IconBox } from "daskapital-ds";

import Link from "next/link";
import { useMemo } from "react";

export type IBlockIconBox = {
  attrs: {
    anchor?: string;
    variant?: string;
    label?: string;
    description?: string;
    icon?: string;
    align?: string;
    href?: {
      url?: string;
      newTab?: boolean;
    };
    className?: string;
    style?: Record<string, unknown>;
  };
};

export default function BlockIconBox({ attrs }: IBlockIconBox) {
  const { variant, label, description, icon, href, align, className, style } = attrs || {};

  const elStyle = useMemo(() => ({
    ...style,
    justifyContent:
      align === "right"
        ? "flex-end"
        : align === "center"
          ? "center"
          : "flex-start",
  }), [style, align]);

  const url = href?.url;
  const newTab = href?.newTab;

  const linkTarget = newTab ? "_blank" : "_parent";

  return (
    <IconBox
      variant={variant}
      label={label}
      description={description?.replace(/\n/g, "<br />")}
      icon={<Icon icon={icon} />}
      className={className}
      style={elStyle}
      linkElement={
        <>
          {url && (
            <Link
              href={url}
              target={linkTarget}
              rel={linkTarget ? "noreferrer noopener" : ""}
              className="linker"
            ></Link>
          )}
        </>
      }
    ></IconBox>
  );
}
