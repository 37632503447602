"use client";

import { Separator } from "daskapital-ds";
import getBlockStyles from "@/lib/wordpress/blocks/getBlockStyles";

export type IBlockSeparator = {
  attrs: {
    anchor?: string;
    backgroundColor?: string;
    height?: string;
    className?: string;
    style?: Record<string, unknown>;
  };
};

export default function BlockSeparator({ attrs }: IBlockSeparator) {
  const { anchor, height, backgroundColor, style, className } = attrs || {};
  const elStyle = getBlockStyles({
    style,
    backgroundColor,
  });

  return (
    <Separator
      // id={anchor || undefined}
      color={elStyle?.backgroundColor}
      height={height}
      className={className}
    ></Separator>
  );
}
