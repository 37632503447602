import { BlockStyleProps } from '../_types/blocksStyle';
import { CSSProperties } from 'react';

interface GetBlockStylesProps extends CSSProperties {
  textColor?: string
  align?: string,
  style?: Record<string, unknown>
  // [x: string]: any
}

export default function getBlockStyles({
  width,
  align,
  style
}: GetBlockStylesProps) {
  const blockStyle: CSSProperties = {}

  // Determine styles, using stylelint-accepted const names.
  const background = (style?.backgroundColor as string)
  const fontsize = (style?.typography as Record<string, string>)?.fontSize
  const fontweight = (style?.typography as Record<string, string>)?.fontWeight
  const textTransform = (style?.typography as Record<string, string>)?.textTransform
  const textAlign = (style?.typography as Record<string, string>)?.textAlign
  const textcolor = (style?.textColor as string)
  const maxWidth = (style?.maxWidth as string)

  const isHex = (hex: string) => {
    return hex.match(/[0-9A-Fa-f]{6}/g) || hex === 'white' || hex === 'black'
  }

  // Only add styles if set.
  if (background) {
    blockStyle.backgroundColor = background
  }
  if (textcolor) {
    blockStyle.color = textcolor
  }
  if (fontsize) {
    blockStyle.fontSize = fontsize as unknown as number
  }
  if (fontweight) {
    blockStyle.fontWeight = fontweight as unknown as number
  }
  if (textTransform) {
    blockStyle.textTransform = textTransform as any
  }
  if (textAlign) {
    blockStyle.textAlign = textAlign !== "left" ? textAlign : "" as any
  }
  if (align) {
    blockStyle.justifyContent = align === 'right' ? 'flex-end' : align === 'center' ? 'center' : ''
  }
  if (background) {
    blockStyle.backgroundColor = background
  }
  if (maxWidth) {
    blockStyle.width = '100%'
    blockStyle.maxWidth = maxWidth ? `${maxWidth}` : 'unset'
  }
  
  if (style?.paddingTop && style?.paddingTop!=="") {
    blockStyle.paddingTop = style?.paddingTop as string
  }
  if (style?.paddingLeft && style?.paddingLeft!=="") {
    blockStyle.paddingLeft = style?.paddingLeft as string
  }
  if (style?.paddingRight && style?.paddingRight!=="") {
    blockStyle.paddingRight = style?.paddingRight as string
  }
  if (style?.paddingBottom && style?.paddingBottom!=="") {
    blockStyle.paddingBottom = style?.paddingBottom as string
  }

  if (style?.marginTop && style?.marginTop!=="") {
    blockStyle.marginTop = style?.marginTop as string
  }
  if (style?.marginLeft && style?.marginLeft!=="") {
    blockStyle.marginLeft = style?.marginLeft as string
  }
  if (style?.marginRight && style?.marginRight!=="") {
    blockStyle.marginRight = style?.marginRight as string
  }
  if (style?.marginBottom && style?.marginBottom!=="") {
    blockStyle.marginBottom = style?.marginBottom as string
  }

  if (width) {
    if (isNaN(width as number)) {
      // If width is not a number, return full string.
      blockStyle.width = width
    } else {
      // If width is a number, return as percent.
      blockStyle.width = `${width}%`
    }
  }

  return blockStyle
}
