import FundProjects from "@/components/common/Simulator/FundProjects";
import InvestInvoices from "@/components/common/Simulator/InvestInvoices";
import InvestProjects from "@/components/common/Simulator/InvestProjects";
import SellInvoices from "@/components/common/Simulator/SellInvoices";

export type IBlockAuthForms = {
  attrs: {
    anchor?: string;
    form?: string;
    getStarted?: {
      url?: string
      target?: string
    };
    knowMore?: {
      url?: string
      target?: string
    };
  };
};

export default function BlockSimulatorForms({attrs}: IBlockAuthForms) {
  if(!attrs?.form || attrs?.form==="invest-invoices") return <InvestInvoices getStarted={attrs?.getStarted} knowMore={attrs?.knowMore} />
  else if(attrs?.form==="invest-projects") return <InvestProjects getStarted={attrs?.getStarted} knowMore={attrs?.knowMore} />
  else if(attrs?.form==="sell-invoices") return <SellInvoices getStarted={attrs?.getStarted} knowMore={attrs?.knowMore}/>
  else if(attrs?.form==="fund-project") return <FundProjects getStarted={attrs?.getStarted} knowMore={attrs?.knowMore} />

  return <></>
}
