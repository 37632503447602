"use client";

import { Spacer } from "daskapital-ds";

export type IBlockSpacer = {
  attrs: {
    anchor?: string;
    customHeight?: string;
  };
};

export default function BlockSpacer({ attrs }: IBlockSpacer) {
  const { anchor, customHeight = "8rem" } = attrs || {};
  return <Spacer customHeight={customHeight} id={anchor || undefined} />;
}
