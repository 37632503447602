"use client"

import React, { useMemo } from 'react'

import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle'
import DisplayBackground from '@/components/common/DisplayBackground/DisplayBackground'
import DisplayImage from '@/components/common/DisplayImage/DisplayImage'
import classNames from 'classnames'
import displayBlock from '@/lib/wordpress/blocks/displayBlock'
import getBlockStyles from '@/lib/wordpress/blocks/getBlockStyles'
import { styled } from 'styled-components'
import { variables } from 'daskapital-ds'

type IBlockGroup = {
  attrs: {
    anchor?: string
    layout?: {
      type?: string,
      columnCount?: number,
      minimumColumnWidth?: string
    }
    tagName?: string
    backgroundImage?: string
    backgroundPosition?: string
    backgroundSize?: string
    backgroundRepeat?: string
    mobileHidden?: boolean
    className?: string
    style?: Record<string, unknown>
    animation?: string;
  }
  innerBlocks?: BlocksProps[]
}
type IBlockGroupStyle = {
  $display?: string,
  $columnCount?: number
  $columnRow?: number
  $columnSpan?: number
  $masonry?: boolean
  $mobileHidden?: boolean
  className?: string
  style?: Record<string, unknown>
}

const StyledGroup = styled.div<IBlockGroupStyle>`
  position: relative;
  
  ${({ $mobileHidden }) => $mobileHidden && (`
    display: none;
  `)}

  ${({ $display }) => $display && (`
    display: flex;
    gap: 1rem;
    flex-flow: column;
  `)}

  @media only screen and (min-width: ${variables.responsiveMedia}) {    
    ${({ $masonry, $display, $columnCount, $columnRow, $columnSpan }) => $masonry ? (`
      column-count: ${$columnCount ? $columnCount : ""};

      & > div {
        break-inside: avoid;
        margin-bottom: 1rem;
      }
    `) : (`
      display: ${$display ? $display : ""};
      grid-template-columns: ${$columnCount ? `repeat(${$columnCount}, minmax(0, 1fr))` : ""};
      grid-column: ${$columnSpan ? `${$columnSpan}` : ""};
      grid-row: ${$columnRow ? `${$columnRow}` : ""};
      gap: 1rem;
    `)}
  }
`;

export default function BlockGroup({ attrs, innerBlocks }: IBlockGroup) {
  const { anchor, layout, tagName, backgroundImage, backgroundPosition, backgroundSize, backgroundRepeat, mobileHidden, className, style, animation } = attrs

  const groupStyle = useMemo(() => ({
    ...getBlockStyles({
      style
    }),
  }), [style]);

  // const imgStyles = useMemo(() => ({
  //   objectPosition: backgroundPosition,
  //   objectFit: backgroundSize,
  //   top: style?.top,
  //   left: style?.left,
  //   right: style?.right,
  //   bottom: style?.bottom,
  //   maxWidth: style?.backgroundWidth,
  //   zIndex: -1,
  // }), [backgroundPosition, backgroundSize]);



  const content = (
    <>
      {backgroundImage && <DisplayBackground
        url={backgroundImage}
        backgroundSize={backgroundSize}
        backgroundPosition={backgroundPosition}
        backgroundRepeat={backgroundRepeat}
        // className={mobileNoBg ? "d-none d-md-block" : ''}
      />}
      {!!innerBlocks?.length &&
        innerBlocks.map((block: BlocksProps, index: number) => {
          return displayBlock(block as BlocksProps, index)
        })}
    </>
  )

  return (
    <StyledGroup
      as={tagName}
      $display={layout?.type === "grid" ? "grid" : undefined}
      $columnCount={layout?.columnCount && layout?.columnCount > 0 ? layout?.columnCount : undefined}
      $columnRow={style?.gridRow ? style?.gridRow as number : undefined}
      $columnSpan={style?.gridColumn ? style?.gridColumn as number : undefined}
      $masonry={false}
      $mobileHidden={mobileHidden}
      className={classNames(
        "block-group",
        className?.replace('is-style-rounded', 'radius-md'),
        animation && `elements_animated ${animation} `
      )}
      style={{
        ...groupStyle,
      }}
    >
      {content}
    </StyledGroup>
  )
}
