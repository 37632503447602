import BlockAccordion from "@/components/blocks/miewtheme/BlockAccordion/BlockAccordion";
import BlockArchiveGrid from "@/components/blocks/miewtheme/BlockArchiveGrid/BlockArchiveGrid";
import BlockBannerRow from "@/components/blocks/miewtheme/BlockBannerRow/BlockBannerRow";
import BlockBidAuctionCard from "@/components/blocks/miewtheme/BlockBidAuctionCard/BlockBidAuctionCard";
import BlockButton from "@/components/blocks/core/BlockButton/BlockButton";
import BlockCarousel from "@/components/blocks/miewtheme/BlockCarousel/BlockCarousel";
import BlockColumns from "@/components/blocks/core/BlockColumns/BlockColumns";
import BlockCounter from "@/components/blocks/miewtheme/BlockCounter/BlockCounter";
import BlockCover from "@/components/blocks/core/BlockCover/BlockCover";
import BlockGravityForm from "@/components/blocks/miewtheme/BlockGravityForm/BlockGravityForm";
import BlockGroup from "@/components/blocks/core/BlockGroup/BlockGroup";
import BlockHeading from "@/components/blocks/core/BlockHeading/BlockHeading";
import BlockHtml from "@/components/blocks/core/BlockHtml/BlockHtml";
import BlockIconBox from "@/components/blocks/miewtheme/BlockIconBox/BlockIconBox";
import BlockIconTitle from "@/components/blocks/miewtheme/BlockIconTitle/BlockIconTitle";
import BlockImage from "@/components/blocks/core/BlockImage/BlockImage";
import BlockInvertedTabs from "@/components/blocks/miewtheme/BlockInvertedTabs/BlockInvertedTabs";
import BlockList from "@/components/blocks/core/BlockList/BlockList";
import BlockLogosGrid from "@/components/blocks/miewtheme/BlockLogosGrid/BlockLogosGrid";
import BlockModal from "@/components/blocks/miewtheme/BlockModal/BlockModal";
import BlockParagraph from "@/components/blocks/core/BlockParagraph/BlockParagraph";
import BlockQuote from "@/components/blocks/core/BlockQuote/BlockQuote";
import BlockReusable from "@/components/blocks/core/BlockReusable/BlockReusable";
import BlockSearchPage from "@/components/blocks/miewtheme/BlockSearchPage/BlockSearchPage";
import BlockSeparator from "@/components/blocks/core/BlockSeparator/BlockSeparator";
import BlockSimulatorForms from "@/components/blocks/miewtheme/BlockSimulatorForms/BlockSimulatorForms";
import BlockSocialLinks from "@/components/blocks/miewtheme/BlockSocialLinks/BlockSocialLinks";
import BlockSpacer from "@/components/blocks/core/BlockSpacer/BlockSpacer";
import BlockTabs from "@/components/blocks/miewtheme/BlockTabs/BlockTabs";
import BlockTaxonomyGrid from "@/components/blocks/miewtheme/BlockTaxonomyGrid/BlockTaxonomyGrid";
import BlockTeamGrid from "@/components/blocks/miewtheme/BlockTeamGrid/BlockTeamGrid";
import BlockVideo from "@/components/blocks/core/BlockVideo/BlockVideo";
import BlockVideoEmbed from "@/components/blocks/core/BlockVideoEmbed/BlockVideoEmbed";
import { BlocksProps } from "../_types/blocksStyle";
import { IGravityForms } from "../forms/types";

export default function displayBlock(block: BlocksProps, index: number) {
  const { attrs, blockName, innerBlocks } = block;

  // prettier-ignore
  switch (blockName) {
    case 'miewtheme/search-page': {
      return <BlockSearchPage attrs={attrs} key={index} />
    }
    case 'core/block': {
      return <BlockReusable innerBlocks={innerBlocks} key={index} />
    }

    case 'core/image': {
      attrs.fill = false
      return (
        <BlockImage
          key={index}
          attrs={attrs}
        />
      )
    }

    case 'core/html': {
      return <BlockHtml attrs={attrs} key={index} />
    }

    case 'core/heading': {
      return <BlockHeading attrs={attrs} key={index} />
    }

    case 'core/paragraph': {
      return <BlockParagraph attrs={attrs} key={index} />
    }

    case 'miewtheme/button': {
      return <BlockButton attrs={attrs} key={index} />
    }

    case 'core/list': {
      return <BlockList attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/counter': {
      return <BlockCounter attrs={attrs} key={index} />
    }

    case 'core/quote': {
      return <BlockQuote attrs={attrs} key={index} />
    }

    case 'core/video': {
      return <BlockVideo attrs={attrs} key={index} />
    }
    case 'core/embed': {
      attrs.embed = attrs?.url;
      return <BlockVideoEmbed attrs={attrs} key={index} />
    }

    case 'core/columns': {
      return (
        <BlockColumns
          attrs={attrs}
          innerBlocks={innerBlocks}
          key={index}
        />
      )
    }

    case 'core/group': {
      return <BlockGroup attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'core/cover': {
      return <BlockCover attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/accordion': {
      return <BlockAccordion attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/spacer': {
      return <BlockSpacer attrs={attrs} key={index} />
    }

    case 'miewtheme/separator': {
      return <BlockSeparator attrs={attrs} key={index} />
    }

    case 'miewtheme/social-links': {
      return <BlockSocialLinks attrs={attrs} key={index} />
    }

    case 'miewtheme/modal': {
      return <BlockModal attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/iconbox': {
      return <BlockIconBox attrs={attrs} key={index} />
    }

    case 'miewtheme/icontitle': {
      return <BlockIconTitle attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/carousel': {
      return <BlockCarousel attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }
    case 'miewtheme/tabs': {
      return <BlockTabs attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }
    case 'miewtheme/team-grid': {
      return <BlockTeamGrid attrs={attrs} key={index} />
    }
    case 'miewtheme/archive-grid': {
      return <BlockArchiveGrid attrs={attrs} key={index} />
    }
    case 'miewtheme/taxonomy-grid': {
      return <BlockTaxonomyGrid attrs={attrs} key={index} />
    }
    case 'gravityforms/form': {
      return <BlockGravityForm attrs={attrs as IGravityForms} key={index} />
    }

    // case 'core/media-text': {
    //   return <BlockMediaText attrs={attrs} innerBlocks={innerBlocks} key={index} valueKey={`media-text-${index}`} />
    // }

    case 'miewtheme/logos-grid': {
      return <BlockLogosGrid attrs={attrs} key={index} />
    }


    case 'miewtheme/simulator-form': {
      return <BlockSimulatorForms attrs={attrs} key={index} />
    }
    case 'miewtheme/bid-auction-card': {
      return <BlockBidAuctionCard attrs={attrs} key={index} />
    }
    case 'miewtheme/inverted-tabs': {
      return <BlockInvertedTabs attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/banner-row': {
      return <BlockBannerRow attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }


    default: {
      if (process.env.NODE_ENV === 'development') {
        console.log('display block not found', blockName, index)
        return <pre key={index}>{JSON.stringify(block, null, 2)}</pre>
      } else {
        return <></>
      }
    }
  }
}
