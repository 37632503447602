export const removeScroll = ((active: boolean, state: any) => {
  if (active) {
    (state && state?.lenis) && state?.lenis?.stop();
    document.documentElement.classList.add("no-scroll");
  } else {
    (state && state?.lenis) && state?.lenis?.start();
    document.documentElement.classList.remove("no-scroll");
  }
})

export const scrollTo = ((state: any, element: string | any, useOffset: boolean = true) => {
  const el = Number.isInteger(element) ? element : document.querySelector<HTMLElement>(element)
  const scrollEl = state?.lenis || Number.isInteger(element) ? el : el?.offsetTop;

  if (state?.lenis) {
    state?.lenis?.scrollTo(scrollEl, {
      offset: useOffset ? document.querySelector<HTMLElement>("#masthead")?.offsetHeight : 0
    });
  } else {
    window.scrollTo({ top: scrollEl, behavior: "smooth" });
  }
})