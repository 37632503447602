"use client";

import { Quote } from "daskapital-ds";
import classNames from "classnames";

export type IBlockQuote = {
  attrs: {
    value?: string;
    citation?: string;
    anchor?: string;
    className?: string;
    animation?: string;
  };
};

export default function BlockQuote({ attrs }: IBlockQuote) {
  const { value, citation, anchor, className, animation } = attrs || {};
  return (
    <Quote
      id={anchor || undefined}
      className={classNames(
        className,
        animation && `elements_animated ${animation}`
      )}
      value={value}
      citation={citation}
    />
  );
}
