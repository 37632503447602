"use client";

import {
  BlogCard,
  Button,
  Chip,
  Column,
  EmptyState,
  Flex,
  FormField,
  FrameCard,
  Heading,
  Icon,
  Loader,
  OffCanvas,
  Row,
  ScrollingNav,
  Separator,
  Slider,
  TeamCard,
} from "daskapital-ds";
import { Field, Form, Formik, useFormik } from "formik";
import { IArchiveGrid, IFilters, IPost, Taxonomy, TaxonomyTerms } from "@/lib/wordpress/_types/archiveGrid";
import React, {
  ChangeEvent,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";

import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import Link from "next/link";
import PaginationStepper from "@/components/common/PaginationStepper/PaginationStepper.component";
import { WpClientRequest } from "@/lib/wordpress/wpClientRequest";
import { formatDate } from "@/lib/helpers/dateFormate";
import isTouchDevice from "@/lib/helpers/isTouchDevice";
import { styled } from "styled-components";
import { useGlobalContext } from "@/lib/globalContext";

const StyledGrid = styled.div`
  width:auto;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
`;

const StyledFilter = styled(Chip)`
  position: relative;
  padding: 1.3rem 2rem;
  border-radius: var(--radius-sm);
  box-shadow: unset;
  white-space: nowrap !important;
  
  & input{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity: 0;
    margin: 0;
    cursor:pointer;
  }
`;

export default function BlockArchiveGrid({ attrs }: IArchiveGrid) {
  const {
    title,
    variant,
    post_type,
    component,
    per_line = 4,
    per_page,
    showFilters,
    showPagination,
    slider,
    sliderMobile,
    anchor,
    grid,
    className,
  } = attrs || {};
  const colSize = 12 / per_line;
  const colSizeMob = 12 / (per_line > 1 ? per_line - 1 : per_line);

  const isMobile = isTouchDevice();

  const [loading, setLoading] = useState<boolean | null>(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState<IFilters | null>();

  const [filteredPosts, setFilteredPosts] = useState<
    IPost[] | undefined
  >(grid?.items);

  const [totalPosts, setTotalPosts] = useState<
    number | undefined
  >(grid?.total || 0);

  const state = useGlobalContext();

  const { lang } = state;

  let activeFilters: string[] = [];
  let displayFilters: TaxonomyTerms[] = [];

  switch (component) {
    case "BlogCard":
      displayFilters = grid && grid.categories && (grid.categories as any).filter((x: any) => x.name === "category")[0]?.items;

      activeFilters = ["category"];
      break;
  }

  useEffect(() => {
    if (grid && !filters) {
      setFilteredPosts(grid.items);
      setTotalPosts(grid?.total);
    }

    if (post_type && filters) {
      setLoading(true);
      WpClientRequest({
        post_type,
        variables: {
          ...filters,
          limit: per_page,
          lang,
        },
      }).then((res) => {
        setFilteredPosts(res.items);
        setTotalPosts(res.total);
        setLoading(false);
      });
    }
  }, [filters, post_type, lang, grid, per_page]);

  const cachedContent = useMemo(() => {
    if (filteredPosts && filteredPosts.length !== 0) {
      return filteredPosts.map((post, i: number) => {
        if (!post) return false;

        const category = post?.categories as unknown as Taxonomy[] || [];
        const categories = category && Object.keys(category).length > 0 ? category[0]?.items : "";

        const imageElement = (
          <DisplayImage
            url={(post?.image ? post?.image : "/assets/placeholder.png") as string}
            alt={post?.title ? (post?.title as string) : ""}
            fill={true}
          />
        );

        switch (component) {
          case "BlogCard":
            return (
              <BlogCard
                key={`blog-card-${i}`}
                variant={variant}
                date={formatDate(post?.date as string, "DATE_SHORT")}
                categories={
                  <>
                    {categories && categories?.map((item, i) => {
                      return <Chip key={`category-${i}`} variant='light'>{item.name}</Chip>
                    })}
                  </>
                }
                imageElement={imageElement}
                title={post.title as string}
                description={post.excerpt as string}
                author={post.author as string}
                className="w-100"
                linkElement={
                  <Link
                    href={post.slug as string}
                    aria-label={`Read more about ${post.title}`}
                    role="link"
                    className="linker"
                  ></Link>
                }
              />
            );

          case "TeamCard":
            const hoverElement = post?.acf?.hover_image?.url && (
              <DisplayImage
                url={post?.acf?.hover_image?.url as string}
                alt={post?.acf?.hover_image?.alt ? post?.acf?.hover_image?.alt : post?.title}
                fill={true}
              />
            );

            return (
              <TeamCard
                key={`team-card-${i}`}
                imageElement={imageElement}
                hoverElement={hoverElement}
                title={post.title as string}
                role={post.acf?.role as string}
                linkElement={
                  <>
                    {post?.acf?.url && (
                      <Link
                        href={post?.acf?.url as string}
                        aria-label={`Read more about ${post.title}`}
                        role="link"
                        target="_blank"
                        className="linker"
                      ></Link>
                    )}
                  </>
                }
              />
            );
          case "CareersCard":
            return (
              <FrameCard
                key={`careers-card-${i}`}
                title={post.title as string}
                linkElement={
                  <Link
                    href={post.slug as string}
                    aria-label={`Read more about ${post.title}`}
                  >
                    <Button>{state?.dict?.common?.knowMore}</Button>
                  </Link>
                }
              />
            );
          case "HelpCenterCard":
            return (
              <FrameCard
                key={`help-card-${i}`}
                title={post.title as string}
                className="mb-4"
                linkElement={
                  <Link
                    href={post.slug as string}
                    aria-label={`Read more about ${post.title}`}
                  >
                    <Button>{state?.dict?.common?.knowMore}</Button>
                  </Link>
                }
              />
            );
        }
      })
    }
  }, [filteredPosts, component])

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      setFilters(values as IFilters);
    },
    onReset: () => {
      setFilters(null);
    },
  });

  return (
    <Row fluid={true} className={className}>
      {(title || (showFilters && activeFilters.length)) && (
        <Column className="mb-6">
          <Separator color="#8D9DA7" height="1px" />
          <Flex align="center" className="mt-4 mb-4">
            {title && (
              <div className="flex-shrink-1">
                <Heading tag="h3" size="h4">
                  {title}
                </Heading>
              </div>
            )}

            {showFilters && activeFilters.length > 0 && (
              <div className="flex-grow-1 w-100">
                <ScrollingNav align="center">
                  <StyledFilter
                    variant="light"
                    hover={true}
                    active={!filters?.category || filters?.category === "all"}
                    className="texts"
                  >
                    <input
                      type="radio"
                      name="category"
                      id={"all"}
                      value={""}
                      checked={!filters?.category || filters?.category === "all"}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.handleSubmit();
                      }}
                    ></input>
                    <p className="texts fw-bold">{state?.dict?.common?.all}</p>
                  </StyledFilter>
                  {grid &&
                    displayFilters &&
                    displayFilters.map((category: TaxonomyTerms, i: number) => {
                      return (
                        <StyledFilter
                          key={`${category.slug}`}
                          variant="light"
                          hover={true}
                          active={category.slug === filters?.category}
                        >
                          <input
                            type="radio"
                            name="category"
                            id={category.slug}
                            value={category.slug}
                            checked={category.slug === filters?.category}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.handleSubmit();
                            }}
                          ></input>
                          <p className="texts fw-bold">{category.name as string}</p>
                        </StyledFilter>
                      );
                    })}
                </ScrollingNav>
              </div>
            )}
          </Flex>
          <Separator color="#8D9DA7" height="1px" />
        </Column>
      )}
      {loading ? (
        <Column className="text-center pt-5 pb-5">
          <Loader label={state?.dict?.common?.loading}></Loader>
        </Column>
      ) : (
        <Suspense
          fallback={<Loader label={state?.dict?.common?.loading}></Loader>}
        >
          {cachedContent ? (
            <>
              {((isMobile && sliderMobile) || slider) ? (
                <Column>
                  <Slider
                    arrows={true}
                    dots={true}
                    slidesToShow={per_line}
                    infinite={false}
                    autoplay={sliderMobile ? false : false}
                    mobileOnly={sliderMobile}
                    mediaQueries={{
                      900: {
                        slidesToShow: per_line > 1 ? per_line - 1 : per_line,
                        autoplay: true
                      },
                      500: {
                        slidesToShow: 1
                      }
                    }}
                  >
                    {cachedContent.map((block, i) => {
                      return <div key={`slider-${component}-${i}`}>{block}</div>
                    })}
                  </Slider>
                </Column>
              ) : (
                <>
                  {cachedContent.map((block, i) => {
                    return <Column
                      key={`${component}-${i}`}
                      sm={12}
                      md={colSizeMob}
                      lg={colSize}
                      className="mb-4"
                    >
                      {block}
                    </Column>
                  })}
                </>
              )}

              {showPagination &&
                <Column sm={12} className="pt-6 pb-10">
                  <PaginationStepper
                    onPageChange={(e) => {
                      setFilters({
                        ...filters,
                        page: e,
                      } as IFilters);
                    }}
                    onChangePageSize={(pageSize) => {
                      // setFilters('s', pageSize);
                    }}
                    pageSize={per_page || 0}
                    totalItems={totalPosts || 0}
                    currentPage={filters?.page || 1}
                  />
                </Column>
              }
            </>
          ) : (
            <Column className="pt-7 pb-5">
              <EmptyState
                variant={"light"}
                label={state?.dict?.common?.noResults}
              ></EmptyState>
            </Column>
          )}
        </Suspense>
      )}
    </Row>
  );
}
