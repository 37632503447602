"use client";

import GravityForm from "@/components/common/GravityForm/GravityForm";
import { IGravityForms } from "@/lib/wordpress/forms/types";

export default function BlockGravityForm({ attrs }: { attrs: IGravityForms }) {
  try {
    return <GravityForm {...attrs} />;
  } catch (ex) {
    console.log("GravityForm Block error", ex);
  }

  return <></>;
}
