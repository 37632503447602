"use client";

import { InvertedTabs, InvertedTabsItem } from "daskapital-ds";

import { BlocksProps } from "@/lib/wordpress/_types/blocksStyle";
import React from "react";
import displayBlock from "@/lib/wordpress/blocks/displayBlock";

export type IBlockInvertedTabs = {
  attrs: {
    title?: string;
    vertical?: boolean;
    className?: string;
    style?: Record<string, unknown>;
  };
  innerBlocks: BlocksProps[];
};
export default function BlockInvertedTabs({ attrs, innerBlocks }: IBlockInvertedTabs) {
  const { className } = attrs || {};

  return (
    <InvertedTabs className={className}>
      {!!innerBlocks?.length &&
        innerBlocks.map((item: BlocksProps, index: number) => {
          const attrs = item?.attrs as Record<string, unknown>;
          return (
            <InvertedTabsItem key={`inverted-tabs-item${index}`} label={attrs.title as string} text={attrs.text as string}>
              {!!item?.innerBlocks?.length &&
                item?.innerBlocks?.map((block: BlocksProps, index: number) => {
                  return displayBlock(block, index);
                })}
            </InvertedTabsItem>
          );
        })}
    </InvertedTabs>
  );
}
