import { styled } from "styled-components";
import { variables } from "daskapital-ds";

interface UnitMap {
  [key: number]: string;
}

export type formsAttrs = {
  getStarted?: {
    url?: string
    target?: string
  };
  knowMore?: {
    url?: string
    target?: string
  };
}

export const StyledBubble = styled.div`
  font-family: var(--font-alternative);
  background-color: white;
  color: ${variables.colors.gray90};
  border: 1px solid ${variables.colors.gray10};
  border-radius: 8px;
  min-width: 7rem;
  height: 43px;
  padding: 8px;
  text-align: center;
`;

export const getInterestRates = () => {
  const interestRates = [
    { from_day: 1, to_day: 30, rate: 0.015 },
    { from_day: 31, to_day: 60, rate: 0.01 },
    { from_day: 61, to_day: 90, rate: 0.01 },
    { from_day: 91, to_day: 180, rate: 0.0075 },
    { from_day: 181, to_day: 365, rate: 0.0075 },
    { from_day: 366, to_day: null, rate: 0.005 }, // null indicates open-ended range
  ];

  return interestRates;

}


/* commission = (30*0.015%) + (30*0.01%) + (30*0.01%) + (10*0.0075%) = 1.125% */
export const calculateInvoiceCommission = (
  invoice_maturity: number,
  gross_total: number
) => {
  const interestRates = getInterestRates();
  let commission = 0;
  let remainingDays = Number(invoice_maturity);

  for (const rate of interestRates) {
    if (remainingDays <= 0) break;

    const periodDays =
      rate.to_day === null
        ? remainingDays
        : Math.min(remainingDays, rate.to_day - rate.from_day + 1);
    commission += periodDays * rate.rate;
    remainingDays -= periodDays;
  }

  return (commission * gross_total) / 100; // Convert percentage to decimal
}

export const calculateInvoiceAmountReceivedNow = ({
  invoice_maturity,
  gross_total,
  max_discount,
}: {
  invoice_maturity: number,
  gross_total: number,
  max_discount: number,
}) => {
  const commission = calculateInvoiceCommission(
    invoice_maturity,
    gross_total
  );
  const amountReceivedNow =
    gross_total -
    (gross_total * max_discount) / 100 -
    gross_total * 0.1 -
    commission;

  return amountReceivedNow;
}

export const calculateInvoiceAmountInvested = ({
  invoice_maturity,
  gross_total,
  max_discount,
}: {
  invoice_maturity: number,
  gross_total: number,
  max_discount: number,
}) => {
  const amountInvested =
    gross_total *
    max_discount *
    (invoice_maturity / 365);

  return amountInvested;
}


export const calculateInvoiceAmountReceived = (gross_total: number) => {
  return 0.1 * gross_total;
}


export const getInstalmentFrequencyValue = (instalment_freq: string) => {
  let inst_freq = 0;
  switch (instalment_freq) {
    case 'MONTHLY':
      inst_freq = 12;
      break;
    case 'BIMONTHLY':
      inst_freq = 6;
      break;
    case 'QUARTERLY':
      inst_freq = 4;
      break;
    case 'SEMIYEARLY':
      inst_freq = 2;
      break;
    case 'ANNUALLY':
      inst_freq = 1;
      break;
    default:
      break;
  }

  return inst_freq;
}
export const roundDecimalNumber = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

/* 
Sobre a equação da simulação dos pagamentos periódicos:
(amount * apr / instalment_freq) / (1 - (1 + apr / instalment_freq)**-(term_months * instalment_freq / 12))
Atenção que instalment_freq aqui é um int com a correspondência MONTH-12, BIMONTHLY-6, QUARTERLY-4, SEMIYEARLY-2, ANNUALLY-1
Outra coisa importante a ter em conta é a comissão de 0.05%, podem só multiplicar o resultado por 1.05 */
export const calculateLoanAmountInstalment = ({
  instalment_freq,
  amount_requested,
  term_months,
  max_apr,
}: {
  instalment_freq: string
  amount_requested: number
  term_months: number
  max_apr: number
}) => {
  const inst_freq = getInstalmentFrequencyValue(instalment_freq);
  const apr = max_apr / 100;
  const formula =
    (amount_requested * apr) /
    inst_freq /
    (1 - (1 + apr / inst_freq) ** -((term_months * inst_freq) / 12));

  const roundedFormula = roundDecimalNumber(formula);
  let comission = 0.005 * roundedFormula;

  if (comission <= 2) {
    comission = 2;
  }
  return roundedFormula + roundDecimalNumber(comission);
}

const getStampTax = (amount_requested: number, term_months: number) => {
  let stampTax = amount_requested;
  if (term_months === 6) {
    stampTax *= 0.0024;
  } else if (term_months === 9) {
    stampTax *= 0.0036;
  } else if (term_months >= 12 && term_months < 60) {
    stampTax *= 0.005;
  } else if (term_months >= 60) {
    stampTax *= 0.006;
  }

  return stampTax;
}

const getCommission = (amount_requested: number) => {
  let amount = amount_requested;
  if (amount_requested > 50000) {
    amount *= 0.98;
  } else if (amount_requested > 20000) {
    amount *= 0.975;
  } else if (amount_requested > 10000) {
    amount *= 0.97;
  } else {
    amount *= 0.965;
  }

  return amount;
}

const getCommissionPercentage = (amount_requested: number) => {
  let amount = amount_requested;
  if (amount_requested > 50000) {
    amount *= 0.02;
  } else if (amount_requested > 20000) {
    amount *= 0.025;
  } else if (amount_requested > 10000) {
    amount *= 0.03;
  } else {
    amount *= 0.035;
  }

  return amount;
}

/* amount > 50000 : amount * (1 - 0.02)
amount > 20000 : amount * (1 - 0.025)
amount > 10000 : amount * (1 - 0.03)
default : amount * (1 - 0.035) */
export const calculateLoanAmountReceived = (amount_requested: number, term_months: number) => {
  let amount = amount_requested;
  const stampTax = getStampTax(amount_requested, term_months);

  amount = getCommission(amount_requested);

  return roundDecimalNumber(
    roundDecimalNumber(amount) - roundDecimalNumber(stampTax)
  );

}

export const calculateLoanTotalPayment = ({
  instalment_freq,
  amount_requested,
  term_months,
  max_apr,
}: {
  instalment_freq: string
  amount_requested: number
  term_months: number
  max_apr: number
}) => {
  const loanAmountInstalment = calculateLoanAmountInstalment({
    instalment_freq,
    amount_requested,
    term_months,
    max_apr,
  });

  const inst_freq = getInstalmentFrequencyValue(instalment_freq);

  const value =
    (loanAmountInstalment * (term_months * inst_freq)) / 12 +
    getStampTax(amount_requested, term_months) +
    getCommissionPercentage(amount_requested);

  return roundDecimalNumber(value);
}


export const formatMonthsToHuman = (months: number) => {
  if (!months) return 0;

  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  const units: UnitMap = {
    0: `${months} months`,
    1: `${years} year${years > 1 ? 's' : ''}`,
  };

  // Check for special case of 6 months
  if (months === 6) {
    units[6] = 'Half a year';
  }

  return `${units[years] || units[1]} ${years > 0 && remainingMonths !== 0 ? `and ${remainingMonths} months` : ''}`; // Fallback for years > 1
}

export const validateStringData = (
  str: string | number | null | undefined,
  fallback: string = ''
) => {
  if (!str) return fallback;
  return str;
};